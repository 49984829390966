import React from "react"
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

function FaqSection() {
  return (
    <div className="section faq">
      <div className="container">
        <h2>FAQs </h2>
        <h3>What is a Hyperlink?</h3>
        <div className="answer">A Hyperlink is a short url that redirects you to any website. When your Hyperlinks are clicked on, they send you a notification with information about the click (location, referrer, etc).
        <br /><br />Notifications are delievered immediately (or hourly / daily / weekly depending on your settings).</div>
        <h3>Where can I use Hyperlinks?</h3>
        <div className="answer">Hyperlinks can be used and shared anywhere. Get push notifications when people click on
          links
          you share on Facebook,
          Twitter, iMessage, Pinterest, SMS &amp; more.</div>
        <h3>How much does Hyperlink cost  ?</h3>
        <div className="answer">Hyperlink is free to use for unlimited links and clicks. If you'd like a custom domain for your Hyperlinks - check out our <a href="https://usehyperlink.com/pricing/">pricing page</a>.</div>
        <h3>How much traffic can a Hyperlink handle?</h3>
        <div className="answer">Hyperlink scales automatically to handle millions of clicks. Hyperlink is built on <a href="https://cloud.google.com/">Google's Cloud Platform</a> and redirects in milliseconds.</div>
        <h3>What if I get too many notifications?</h3>
        <div className="answer">
          You can update your settings to recieve to weekly, daily, or hourly click summaries instead of getting notified per-click. You can also
          turn off notifications per link.
        </div>
        <h3>Is Hyperlink only available on mobile devices?</h3>
        <div className="answer">Hyperlink is available on <a href="https://apps.apple.com/us/app/hyperlink-custom-push-links/id1480418373?ls=1">iOS</a> and <a href="https://play.google.com/store/apps/details?id=app.siggi.android.link">Android</a>. We
          also have a <a href="https://chrome.google.com/webstore/detail/hyperlink/dlgncnofjdagniahkcimljhineapppbi">Chrome
            extension</a> and a <a href="/app/">web app</a>.
Notifications are
delivered to your
          mobile device, or to a Slack channel.</div>
        <h3>Can I use Hyperlink with my own domain?</h3>
        <div className="answer">
          Yes. Hyperlink supports creating links with your own custom domain instead of using Hyperlink branded links. Set up your custom domain <a href="https://usehyperlink.com/subscription/">here</a>.
        </div>
        <h3>Can I cancel my subscription at any time?</h3>
        <div className="answer">
          Yes, you can cancel your account at any time. You will be downgraded to using Hyperlink branded links.
        </div>
        <h3>Is Hyperlink SEO friendly?</h3>
        <div className="answer">
          Yes, Hyperlink redirects are blazing fast and pass page rank.
        </div>
        <h3>Does Hyperlink have an API?</h3>
        <div className="answer">
          Yes, Hyperlink has an api you can use to send yourself push notifications. Read our <a href="http://usehyperlink.com/documentation/">documentation</a> for more information.<br /><br />An api for Hyperlink creation is coming soon. In the meantime you can use our <a href="https://zapier.com/developer/public-invite/66721/a7078fc6d28dc7d1d70494c08f845da5/">Zapier Integration</a> to create Hyperlinks programmatically.
        </div>
        <h3>Does Hyperlink have an affiliate program?</h3>
        <div className="answer">
          Yes, shoot us an email at <a href="mailto:affiliate@usehyperlink.com">affiliate@usehyperlink.com</a> and we'll send you an affiliate link you can share with your audience.
        </div>
      </div>
    </div>
  );
}

export default FaqSection
